import colors from '../cocktail-colors.json';

export default function DisplayCocktail(props) {
  let cocktail = props.cocktail;
  if (!("drinkware" in cocktail)) {

    return <Lowball cocktail={cocktail}/>;
 
  }
  let g = cocktail.drinkware.toLowerCase();
    if (["pilsner glass","pint","pint glass","highball","collins"].indexOf(g) > 0) {
    return <Collins cocktail={cocktail}/>
  }
  else if (g.includes("shot")) {
      return <Shot cocktail={cocktail}/>
  }
    else if (["flute", "cocktail", "coupe or cocktail glass", "coupe"].indexOf(g) > 0) {
      return <Martini cocktail={cocktail}/>
  }
    else if (["hurricane glass", "margarita"].indexOf(g) > 0) {
      return <Marg cocktail={cocktail}/>
  }
  return <Lowball cocktail={cocktail}/>;
}

function getColor(cocktail) {
  if ("color" in cocktail) {
    return colors[cocktail.color].drink_color;
  }
  return "#FF8A00"
}

function hasIce(cocktail) {
  if (!("served" in cocktail)) return false;
  return cocktail.served.toLowerCase().includes("rocks") ||  cocktail.served.toLowerCase().includes("ice")
}

function isFizzy(cocktail) {
  if (cocktail.name.includes("Seven")) return true;
  if (JSON.stringify(cocktail).includes("stout")) return true;
  return (cocktail.name.toLowerCase().includes("fizz") || cocktail.ingredients.includes("soda")) || ((JSON.stringify(cocktail).toLowerCase().includes("cola") || JSON.stringify(cocktail).toLowerCase().includes("beer")))
}

function Collins(props) {
  let color = getColor(props.cocktail);
  let lime = "garnish" in props.cocktail ? props.cocktail.garnish.toLowerCase().includes("lime") : null

  let mint = "garnish" in props.cocktail ? props.cocktail.garnish.toLowerCase().includes("mint") : null

  let cherry = "garnish" in props.cocktail ? props.cocktail.garnish.toLowerCase().includes("cherry") : null

  let orange = "garnish" in props.cocktail ? props.cocktail.garnish.toLowerCase().includes("orange") : null

  let twist = "garnish" in props.cocktail ? props.cocktail.garnish.toLowerCase().includes("twist") : null

  let lemon = "garnish" in props.cocktail ? props.cocktail.garnish.toLowerCase().includes("lemon") : null
  let rocks = hasIce(props.cocktail);
  let fizzy = isFizzy(props.cocktail);

  return <div className="collins-display">
    <svg viewBox="0 0 140 198" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M36.5151 197.246L27.2025 25.8895H115.296L105.983 197.246H36.5151Z" fill="white" fill-opacity="0.4" stroke="#79838D"/>
    <path d="M31.2883 43.7408L111.176 43.7408L102.444 178.099H40.0549L31.2883 43.7408Z" fill={color} fill-opacity="0.4"/>
    { rocks ? <> <rect width="24.4945" height="17.3196" transform="matrix(0.741809 -0.482913 1.18304 0.182969 42.4729 150.468)" fill="#F9FDFF"/>
    <rect width="24.4945" height="17.3196" transform="matrix(0.741809 -0.482913 1.18304 0.182969 42.4729 150.468)" fill="#F9FDFF"/>
    <rect width="24.4945" height="17.3196" transform="matrix(0.741809 -0.482913 1.18304 0.182969 42.4729 150.468)" fill="#F9FDFF"/>
    <path d="M62.7584 153.637L80.9287 141.808L80.9287 160.906L62.7584 175.212L62.7584 153.637Z" fill="#F9FDFF"/>
    <path d="M62.7584 153.637L80.9287 141.808L80.9287 160.906L62.7584 175.212L62.7584 153.637Z" fill="#F9FDFF"/>
    <path d="M62.7584 153.637L80.9287 141.808L80.9287 160.906L62.7584 175.212L62.7584 153.637Z" fill="#E9F4FB"/>
    <path d="M62.7584 153.637L42.504 150.471L42.5041 171.194L62.7584 175.212L62.7584 153.637Z" fill="#F9FDFF"/>
    <path d="M62.7584 153.637L42.504 150.471L42.5041 171.194L62.7584 175.212L62.7584 153.637Z" fill="#F9FDFF"/>
    <path d="M62.7584 153.637L42.504 150.471L42.5041 171.194L62.7584 175.212L62.7584 153.637Z" fill="#E2EEF5"/>
    <path d="M101.981 165.56L92.5303 174.889L71.8213 175.893L81.2719 166.563L101.981 165.56Z" fill="#F9FDFF"/>
    <path d="M101.981 165.56L92.5303 174.889L71.8213 175.893L81.2719 166.563L101.981 165.56Z" fill="#F9FDFF"/>
    <path d="M101.981 165.56L92.5303 174.889L71.8213 175.893L81.2719 166.563L101.981 165.56Z" fill="#DDECF5"/>
    <path d="M81.4719 166.522L72.1397 175.735L68.3106 157.025L77.146 145.385L81.4719 166.522Z" fill="#F9FDFF"/>
    <path d="M81.4719 166.522L72.1397 175.735L68.3106 157.025L77.146 145.385L81.4719 166.522Z" fill="#F9FDFF"/>
    <path d="M81.4719 166.522L72.1397 175.735L68.3106 157.025L77.146 145.385L81.4719 166.522Z" fill="#E2EEF5"/>
    <path d="M81.4719 166.522L101.95 165.563L97.7947 145.261L77.146 145.385L81.4719 166.522Z" fill="#F9FDFF"/>
    <path d="M81.4719 166.522L101.95 165.563L97.7947 145.261L77.146 145.385L81.4719 166.522Z" fill="#F9FDFF"/>
    <path d="M81.4719 166.522L101.95 165.563L97.7947 145.261L77.146 145.385L81.4719 166.522Z" fill="#E9F4FB"/>
    <path d="M72.5118 112.196L85.361 108.842L103.797 118.327L90.9482 121.681L72.5118 112.196Z" fill="#F9FDFF"/>
    <path d="M72.5118 112.196L85.361 108.842L103.797 118.327L90.9482 121.681L72.5118 112.196Z" fill="#F9FDFF"/>
    <path d="M72.5118 112.196L85.361 108.842L103.797 118.327L90.9482 121.681L72.5118 112.196Z" fill="#F9FDFF"/>
    <path d="M90.7543 121.617L103.443 118.305L97.4039 136.422L83.9322 142.085L90.7543 121.617Z" fill="#F9FDFF"/>
    <path d="M90.7543 121.617L103.443 118.305L97.4039 136.422L83.9322 142.085L90.7543 121.617Z" fill="#F9FDFF"/>
    <path d="M90.7543 121.617L103.443 118.305L97.4039 136.422L83.9322 142.085L90.7543 121.617Z" fill="#E9F4FB"/>
    <path d="M90.7544 121.617L72.5404 112.208L65.9877 131.868L83.9323 142.085L90.7544 121.617Z" fill="#F9FDFF"/>
    <path d="M90.7544 121.617L72.5404 112.208L65.9877 131.868L83.9323 142.085L90.7544 121.617Z" fill="#F9FDFF"/>
    <path d="M90.7544 121.617L72.5404 112.208L65.9877 131.868L83.9323 142.085L90.7544 121.617Z" fill="#E2EEF5"/>
    <path d="M42.6228 138.397L39.2685 125.548L48.7538 107.111L52.1081 119.96L42.6228 138.397Z" fill="#F9FDFF"/>
    <path d="M42.6228 138.397L39.2685 125.548L48.7538 107.111L52.1081 119.96L42.6228 138.397Z" fill="#F9FDFF"/>
    <path d="M42.6228 138.397L39.2685 125.548L48.7538 107.111L52.1081 119.96L42.6228 138.397Z" fill="#F9FDFF"/>
    <path d="M52.0435 120.154L48.7313 107.466L66.8488 113.505L72.5117 126.976L52.0435 120.154Z" fill="#F9FDFF"/>
    <path d="M52.0435 120.154L48.7313 107.466L66.8488 113.505L72.5117 126.976L52.0435 120.154Z" fill="#F9FDFF"/>
    <path d="M52.0435 120.154L48.7313 107.466L66.8488 113.505L72.5117 126.976L52.0435 120.154Z" fill="#E9F4FB"/>
    <path d="M52.0435 120.154L42.6351 138.368L62.2951 144.921L72.5117 126.976L52.0435 120.154Z" fill="#F9FDFF"/>
    <path d="M52.0435 120.154L42.6351 138.368L62.2951 144.921L72.5117 126.976L52.0435 120.154Z" fill="#F9FDFF"/>
    <path d="M52.0435 120.154L42.6351 138.368L62.2951 144.921L72.5117 126.976L52.0435 120.154Z" fill="#E2EEF5"/>
    <rect width="24.4945" height="17.3196" transform="matrix(0.810928 -0.354799 1.13705 0.374397 38.498 62.2914)" fill="#F9FDFF"/>
    <rect width="24.4945" height="17.3196" transform="matrix(0.810928 -0.354799 1.13705 0.374397 38.498 62.2914)" fill="#F9FDFF"/>
    <rect width="24.4945" height="17.3196" transform="matrix(0.810928 -0.354799 1.13705 0.374397 38.498 62.2914)" fill="#F9FDFF"/>
    <path d="M57.9899 68.7424L77.8531 60.0518L74.723 78.8909L54.4536 90.0258L57.9899 68.7424Z" fill="#F9FDFF"/>
    <path d="M57.9899 68.7424L77.8531 60.0518L74.723 78.8909L54.4536 90.0258L57.9899 68.7424Z" fill="#F9FDFF"/>
    <path d="M57.9899 68.7424L77.8531 60.0518L74.723 78.8909L54.4536 90.0258L57.9899 68.7424Z" fill="#E9F4FB"/>
    <path d="M57.9899 68.7424L38.5284 62.2991L35.1319 82.7421L54.4537 90.0258L57.9899 68.7424Z" fill="#F9FDFF"/>
    <path d="M57.9899 68.7424L38.5284 62.2991L35.1319 82.7421L54.4537 90.0258L57.9899 68.7424Z" fill="#F9FDFF"/>
    <path d="M57.9899 68.7424L38.5284 62.2991L35.1319 82.7421L54.4537 90.0258L57.9899 68.7424Z" fill="#E2EEF5"/>
    <path d="M42.8124 90.0983L54.9788 84.7752L74.672 91.2596L62.5057 96.5827L42.8124 90.0983Z" fill="#F9FDFF"/>
    <path d="M42.8124 90.0983L54.9788 84.7752L74.672 91.2596L62.5057 96.5827L42.8124 90.0983Z" fill="#F9FDFF"/>
    <path d="M42.8124 90.0983L54.9788 84.7752L74.672 91.2596L62.5057 96.5827L42.8124 90.0983Z" fill="#DDECF5"/>
    <path d="M62.3042 96.5492L74.318 91.2929L71.1879 110.132L58.768 117.833L62.3042 96.5492Z" fill="#F9FDFF"/>
    <path d="M62.3042 96.5492L74.318 91.2929L71.1879 110.132L58.768 117.833L62.3042 96.5492Z" fill="#F9FDFF"/>
    <path d="M62.3042 96.5492L74.318 91.2929L71.1879 110.132L58.768 117.833L62.3042 96.5492Z" fill="#E2EEF5"/>
    <path d="M62.3042 96.5492L42.8427 90.106L39.4462 110.549L58.768 117.833L62.3042 96.5492Z" fill="#F9FDFF"/>
    <path d="M62.3042 96.5492L42.8427 90.106L39.4462 110.549L58.768 117.833L62.3042 96.5492Z" fill="#F9FDFF"/>
    <path d="M62.3042 96.5492L42.8427 90.106L39.4462 110.549L58.768 117.833L62.3042 96.5492Z" fill="#E9F4FB"/>
    <path d="M73.8594 86.1707L86.0257 80.8477L105.719 87.3321L93.5527 92.6551L73.8594 86.1707Z" fill="#F9FDFF"/>
    <path d="M73.8594 86.1707L86.0257 80.8477L105.719 87.3321L93.5527 92.6551L73.8594 86.1707Z" fill="#F9FDFF"/>
    <path d="M73.8594 86.1707L86.0257 80.8477L105.719 87.3321L93.5527 92.6551L73.8594 86.1707Z" fill="#F9FDFF"/>
    <path d="M93.3512 92.6216L105.365 87.3654L102.235 106.204L89.815 113.905L93.3512 92.6216Z" fill="#F9FDFF"/>
    <path d="M93.3512 92.6216L105.365 87.3654L102.235 106.204L89.815 113.905L93.3512 92.6216Z" fill="#F9FDFF"/>
    <path d="M93.3512 92.6216L105.365 87.3654L102.235 106.204L89.815 113.905L93.3512 92.6216Z" fill="#E9F4FB"/>
    <path d="M93.3512 92.6216L73.8897 86.1784L70.4932 106.621L89.815 113.905L93.3512 92.6216Z" fill="#F9FDFF"/>
    <path d="M93.3512 92.6216L73.8897 86.1784L70.4932 106.621L89.815 113.905L93.3512 92.6216Z" fill="#F9FDFF"/>
    <path d="M93.3512 92.6216L73.8897 86.1784L70.4932 106.621L89.815 113.905L93.3512 92.6216Z" fill="#E2EEF5"/>
    <path d="M72.5843 59.326L84.7506 54.0029L104.444 60.4873L92.2775 65.8104L72.5843 59.326Z" fill="#F9FDFF"/>
    <path d="M72.5843 59.326L84.7506 54.0029L104.444 60.4873L92.2775 65.8104L72.5843 59.326Z" fill="#F9FDFF"/>
    <path d="M72.5843 59.326L84.7506 54.0029L104.444 60.4873L92.2775 65.8104L72.5843 59.326Z" fill="#F9FDFF"/>
    <path d="M92.0761 65.7769L104.09 60.5206L100.96 79.3598L88.5398 87.0603L92.0761 65.7769Z" fill="#F9FDFF"/>
    <path d="M92.0761 65.7769L104.09 60.5206L100.96 79.3598L88.5398 87.0603L92.0761 65.7769Z" fill="#F9FDFF"/>
    <path d="M92.0761 65.7769L104.09 60.5206L100.96 79.3598L88.5398 87.0603L92.0761 65.7769Z" fill="#E9F4FB"/>
    <path d="M92.0761 65.7769L72.6145 59.3337L69.2181 79.7766L88.5399 87.0603L92.0761 65.7769Z" fill="#F9FDFF"/>
    <path d="M92.0761 65.7769L72.6145 59.3337L69.2181 79.7766L88.5399 87.0603L92.0761 65.7769Z" fill="#F9FDFF"/>
    <path d="M92.0761 65.7769L72.6145 59.3337L69.2181 79.7766L88.5399 87.0603L92.0761 65.7769Z" fill="#E2EEF5"/>
    <path d="M55.9251 37.356L68.0914 32.0329L87.7847 38.5173L75.6183 43.8404L55.9251 37.356Z" fill="#F9FDFF"/>
    <path d="M55.9251 37.356L68.0914 32.0329L87.7847 38.5173L75.6183 43.8404L55.9251 37.356Z" fill="#F9FDFF"/>
    <path d="M55.9251 37.356L68.0914 32.0329L87.7847 38.5173L75.6183 43.8404L55.9251 37.356Z" fill="#F9FDFF"/>
    <path d="M75.4169 43.8069L87.4307 38.5506L84.3006 57.3898L71.8807 65.0903L75.4169 43.8069Z" fill="#F9FDFF"/>
    <path d="M75.4169 43.8069L87.4307 38.5506L84.3006 57.3898L71.8807 65.0903L75.4169 43.8069Z" fill="#F9FDFF"/>
    <path d="M75.4169 43.8069L87.4307 38.5506L84.3006 57.3898L71.8807 65.0903L75.4169 43.8069Z" fill="#E9F4FB"/>
    <path d="M75.4169 43.8069L55.9554 37.3637L52.5589 57.8066L71.8807 65.0903L75.4169 43.8069Z" fill="#F9FDFF"/>
    <path d="M75.4169 43.8069L55.9554 37.3637L52.5589 57.8066L71.8807 65.0903L75.4169 43.8069Z" fill="#F9FDFF"/>
    <path d="M75.4169 43.8069L55.9554 37.3637L52.5589 57.8066L71.8807 65.0903L75.4169 43.8069Z" fill="#E2EEF5"/>
    </> : null }
    { lime ? <><path d="M134.118 35.4955C133.456 37.9658 124.079 34.55 112.234 31.376C100.388 28.202 90.0739 28.2842 91.2215 24.0014C94.0448 13.4645 105.936 7.49577 117.782 10.6698C129.627 13.8438 136.941 24.9586 134.118 35.4955Z" fill="#599E00"/>
    <path d="M132.096 35.4389C131.557 37.4511 122.989 34.4196 112.193 31.5269C101.397 28.6341 92.0661 28.452 93.0009 24.9634C95.3007 16.3805 105.917 11.7677 116.713 14.6604C127.508 17.5531 134.396 26.856 132.096 35.4389Z" fill="#C0E195"/> </> : null}

      { lemon ? <><path d="M50.8004 22.6816C51.4623 25.152 41.6338 26.8822 29.7883 30.0562C17.9427 33.2302 9.05145 38.4586 7.90386 34.1757C5.08052 23.6389 12.3945 12.524 24.24 9.35003C36.0856 6.17602 47.9771 12.1448 50.8004 22.6816Z" fill="#FFE600"/>
        <path d="M49.0209 23.6436C49.5601 25.6558 40.6245 27.3144 29.8287 30.2071C19.0328 33.0998 10.8606 37.6078 9.92584 34.1191C7.62606 25.5362 14.5135 16.2334 25.3093 13.3407C36.1051 10.4479 46.7211 15.0607 49.0209 23.6436Z" fill="#FFF59A"/> </> : null}   { mint ? <>  <path d="M94.9458 58.4696C93.667 60.6844 85.5887 51.9124 74.2081 49.4471C63.5876 43.3154 50.5189 46.0682 52.7359 42.2283C58.1901 32.7812 71.2213 30.0936 81.8417 36.2253C92.4621 42.357 100.4 49.0225 94.9458 58.4696Z" fill="#65B500"/>
      <path d="M55.5561 41.1878C68.1776 37.1688 85.866 40.7651 92.2414 51.4808" stroke="#61A011" stroke-linecap="round"/>
    <path d="M56.338 39.7712C65.6334 35.589 77.9548 37.1149 81.6755 45.2046" stroke="#61A011" stroke-linecap="round"/>
    <path d="M77.3085 35.6111C77.6432 39.238 62.8265 37.7547 56.4956 40.7631L69.8824 38.6097L84.6781 43.2996L90.2697 42.173" stroke="#61A011" stroke-linecap="round"/>
    <path d="M80.8925 35.704C81.5545 38.1743 69.6395 37.6838 59.8489 43.9879C48.0034 47.1619 40.7089 58.3494 39.5613 54.0666C36.738 43.5297 44.0519 32.4149 55.8975 29.2409C67.7431 26.0669 78.0692 25.1671 80.8925 35.704Z" fill="#599E00"/>
    <path d="M40.8199 51.3366C46.9027 39.57 61.9533 29.6054 74.0386 32.6744" stroke="#4F8C01" stroke-linecap="round"/>
    <path d="M40.371 49.782C43.9866 40.2519 53.7782 32.6184 62.1293 35.7077" stroke="#4F8C01" stroke-linecap="round"/>
        <path d="M52.2578 32.012C55.0591 34.34 43.5332 43.7681 41.1838 50.372L49.127 39.3834L62.9054 32.2375L66.0627 27.487" stroke="#4F8C01" stroke-linecap="round"/> </> : null }
      { twist ? <>  <path d="M66.355 51.3675C61.6316 51.2916 56.4459 45.6263 54.4434 42.8032C53.7069 42.0518 52.3615 39.5323 52.8723 35.4652C54.2951 34.7748 57.4002 34.2134 58.4374 37.4907C59.7339 41.5873 61.8848 43.3368 65.0159 42.0599C68.147 40.7831 75.8611 41.8992 80.249 43.4963C84.6369 45.0933 89.7009 53.8235 89.9954 57.6763C90.2899 61.529 84.0295 66.7416 82.1429 67.2631C80.2563 67.7847 78.8053 65.4442 78.239 62.3383C77.6727 59.2324 72.2592 51.4624 66.355 51.3675Z" fill="url(#paint0_linear_41_1193)"/> </> : null}
      { cherry | twist ? <><line x1="39.5418" y1="10.0537" x2="71.9293" y2="47.8296" stroke="#ABABAB"/>
        <ellipse cx="38.8215" cy="8.93858" rx="2.49072" ry="4.1512" transform="rotate(-35 38.8215 8.93858)" fill="#C4C4C4"/> </> : null}
      {cherry ? <><circle cx="65.1299" cy="39.039" r="8.21035" fill="url(#paint1_linear_41_1193)"/> </> : null }
      { orange ? <><circle cx="27.6577" cy="24.5499" r="23.6051" fill="#FFB800"/>
        <circle cx="27.6576" cy="24.5499" r="21.6582" fill="#FFE297"/> </> : null }
    <path d="M31.2883 43.7408L111.176 43.7408L102.444 178.099H40.0549L31.2883 43.7408Z" fill={color} fill-opacity="0.5"/>
      {fizzy ? <path d="M31.2883 43.7408L111.176 43.7408L109.826 62.2914H33.4991L31.2883 43.7408Z" fill="white" fill-opacity="0.9"/> : null}
    <path d="M30.0957 25.3895H112.403L102.444 178.099H40.0548L30.0957 25.3895Z" fill="url(#paint2_linear_41_1193)"/>
    <path d="M40.5232 177.599L30.6294 25.8895H111.869L101.975 177.599H40.5232Z" stroke="#D2D2D2" stroke-opacity="0.5"/>
    <path d="M36.5151 197.246L27.2025 25.8895H115.296L105.983 197.246H36.5151Z" stroke="#79838D"/>
    <defs>
    <linearGradient id="paint0_linear_41_1193" x1="49.7241" y1="44.1147" x2="89.983" y2="58.7677" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF9D42"/>
    <stop offset="0.9999" stop-color="#FFC700" stop-opacity="0.911458"/>
    <stop offset="1" stop-color="#FFD542" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint1_linear_41_1193" x1="65.1299" y1="30.8286" x2="65.1299" y2="47.2493" gradientUnits="userSpaceOnUse">
    <stop stop-color="#F43232"/>
    <stop offset="1" stop-color="#FF0000"/>
    </linearGradient>
    <linearGradient id="paint2_linear_41_1193" x1="36.1056" y1="105.566" x2="103.872" y2="105.182" gradientUnits="userSpaceOnUse">
    <stop stop-color="white" stop-opacity="0.03"/>
    <stop offset="0.200587" stop-color="white" stop-opacity="0.1"/>
    <stop offset="0.380986" stop-color="white" stop-opacity="0.47"/>
    <stop offset="0.787253" stop-color="white" stop-opacity="0.42"/>
    <stop offset="1" stop-color="white" stop-opacity="0.08"/>
    </linearGradient>
    </defs>
    </svg>
  </div>;
}

function Shot(props) {
  let color = getColor(props.cocktail);
  
  return <div className="shot-display">
    <svg viewBox="0 0 53 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.1466 72.3083L0.69993 1.21811H52.1878L46.7411 72.3083H6.1466Z" fill="white" fill-opacity="0.4" stroke="#79838D"/>
    <path d="M6.78247 8.39375L46.289 8.39375L41.9706 64.5907H11.1178L6.78247 8.39375Z" fill={color} fill-opacity="0.4"/>
    <path d="M5.79297 0.718109H47.0746L42.0795 64.5908H10.788L5.79297 0.718109Z" fill="url(#paint0_linear_41_1316)"/>
    <path d="M11.2504 64.0908L6.3336 1.21811H46.534L41.6171 64.0908H11.2504Z" stroke="#D2D2D2" stroke-opacity="0.5"/>
    <defs>
    <linearGradient id="paint0_linear_41_1316" x1="8.80728" y1="34.253" x2="42.7953" y2="34.0218" gradientUnits="userSpaceOnUse">
    <stop stop-color="white" stop-opacity="0.03"/>
    <stop offset="0.200587" stop-color="white" stop-opacity="0.1"/>
    <stop offset="0.380986" stop-color="white" stop-opacity="0.47"/>
    <stop offset="0.787253" stop-color="white" stop-opacity="0.42"/>
    <stop offset="1" stop-color="white" stop-opacity="0.08"/>
    </linearGradient>
    </defs>
    </svg>
  </div>;
}

function Martini(props) {
  let cherry = "garnish" in props.cocktail ? props.cocktail.garnish.toLowerCase().includes("cherry") : null

  let twist = "garnish" in props.cocktail ? props.cocktail.garnish.toLowerCase().includes("twist") : null

  let olive = "garnish" in props.cocktail ? props.cocktail.garnish.toLowerCase().includes("olive") : null
  let color = getColor(props.cocktail);

  return <div className="martini-display">
<svg viewBox="0 0 159 229" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M131.263 195.666C131.263 197.612 129.96 199.565 127.367 201.423C124.786 203.273 121.019 204.96 116.319 206.384C106.925 209.232 93.9178 211.001 79.5289 211.001C65.1399 211.001 52.1326 209.232 42.7389 206.384C38.039 204.96 34.272 203.273 31.691 201.423C29.0979 199.565 27.7949 197.612 27.7949 195.666C27.7949 193.72 29.0979 191.767 31.691 189.909C34.272 188.059 38.039 186.373 42.7389 184.948C52.1326 182.1 65.1399 180.331 79.5289 180.331C93.9178 180.331 106.925 182.1 116.319 184.948C121.019 186.373 124.786 188.059 127.367 189.909C129.96 191.767 131.263 193.72 131.263 195.666Z" fill="white" fill-opacity="0.4" stroke="#79838D"/>
<path d="M74.0734 103.466V103.272L73.9419 103.128L15.4016 39.2982L143.592 39.2982L84.5972 103.127L84.4644 103.271V103.466L84.4644 186.21L83.8817 180.716L83.8343 180.269H83.3845H75.1483H74.6984L74.6511 180.716L74.0734 186.181L74.0734 103.466Z" fill="white" fill-opacity="0.4" stroke="#79838D"/>
<path d="M79.747 99.9662H78.7921L26.4077 43.2981L132.586 43.2981L79.747 99.9662Z" fill="white" fill-opacity="0.4" stroke="#F3F9FF"/>
  {cherry ? <circle cx="79.4999" cy="86.3832" r="8.21035" fill="#F43232"/> : null}
{twist ? <> <line x1="125.205" y1="40.4362" x2="109.396" y2="56.2173" stroke="#ABABAB"/>
  <path d="M119.589 52.7683C124.294 53.1865 124.034 50.1889 126.321 47.5906C128.607 44.9922 129.973 39.1665 129.89 35.0683C131.795 31.1625 135.32 38.8045 137.933 37.6612C141.874 33.298 128.708 27.1594 127.249 28.3406C125.914 29.4213 124.491 34.4898 125.83 37.1975C124.29 38.6539 125.78 40.0744 124.208 43.2967C122.325 47.1587 122.699 49.7078 119.719 48.1107C116.738 46.5135 111.435 48.3529 106.904 49.4826C102.374 50.6122 102.373 52.6813 101.677 56.4822C100.982 60.283 102.89 61.1141 104.712 61.8301C106.533 62.546 106.224 54.3481 108.331 54.4089C111.487 54.5 113.707 52.2455 119.589 52.7683Z" fill="url(#paint0_linear_41_1136)"/>
 <line x1="144.501" y1="22.6315" x2="127.99" y2="37.6225" stroke="#ABABAB"/>
    <ellipse cx="145.606" cy="21.9205" rx="2.49072" ry="4.1512" transform="rotate(55 145.606 21.9205)" fill="#C4C4C4"/> </> : null}
  {olive ? <> 
<line x1="25.2604" y1="23.4434" x2="57.6479" y2="61.2193" stroke="#ABABAB"/>
<ellipse cx="24.5401" cy="22.3283" rx="2.49072" ry="4.1512" transform="rotate(-35 24.5401 22.3283)" fill="#C4C4C4"/>
  <circle cx="50.8485" cy="52.4287" r="8.21035" fill="url(#paint1_linear_41_1136)"/> 
  <circle cx="54.2106" cy="55.7907" r="2.84831" fill="#FF6C6C"/> </> : null }
<path d="M78.5733 100.466L51.9189 71.6322L38.5917 57.2151L31.9281 50.0066L127.014 50.0066L120.293 57.2151L106.85 71.6322L79.9644 100.466H78.5733Z" fill={color} fill-opacity="0.75"/>
<defs>
<linearGradient id="paint0_linear_41_1136" x1="134.712" y1="51.7525" x2="93.1418" y2="62.1171" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFEC42"/>
<stop offset="0.9999" stop-color="#FFC700" stop-opacity="0.911458"/>
<stop offset="1" stop-color="#FFD542" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_41_1136" x1="50.8485" y1="44.2183" x2="50.8485" y2="60.639" gradientUnits="userSpaceOnUse">
<stop stop-color="#90AC6C"/>
<stop offset="1" stop-color="#BAC2A1"/>
</linearGradient>
</defs>
</svg>
  </div>;
}

function Marg(props) {

  let lime = "garnish" in props.cocktail ? props.cocktail.garnish.toLowerCase().includes("lime") : null
  if(props.cocktail.name.toLowerCase() === "margarita") {lime = true}
  let color = getColor(props.cocktail);
  return <div className="marg-display">
  <svg viewBox="0 0 134 180" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M43.7087 76.4022C46.9684 80.0815 51.2157 80.7214 51.2157 80.7214H61.5678C61.5678 80.7214 65.8151 80.0815 69.0747 76.4022C72.3344 72.7228 78.7491 62.7499 78.7491 50.1295C78.7491 37.5092 93.8367 42.8866 100.098 35.7534C106.359 28.6201 105.03 27.3895 105.03 27.3895L7.7536 27.3895C7.7536 27.3895 6.42444 28.6202 12.6856 35.7534C18.9468 42.8866 34.0344 37.5092 34.0344 50.1296C34.0344 62.7499 40.4491 72.7229 43.7087 76.4022Z" fill={color} fill-opacity="0.75"/>
    {lime ? <> <path d="M128.105 30.9187C127.443 33.389 118.066 29.9732 106.221 26.7992C94.3751 23.6252 84.0609 23.7075 85.2084 19.4246C88.0318 8.88775 99.9233 2.91899 111.769 6.093C123.614 9.267 130.928 20.3818 128.105 30.9187Z" fill="#599E00"/>
    <path d="M126.083 30.8621C125.544 32.8743 116.976 29.8428 106.18 26.9501C95.3844 24.0574 86.0531 23.8752 86.9879 20.3866C89.2876 11.8037 99.9037 7.19091 110.7 10.0836C121.495 12.9764 128.383 22.2792 126.083 30.8621Z" fill="#C0E195"/> </> : null }
  <path d="M108.386 163.924C108.386 165.871 107.083 167.823 104.49 169.681C101.909 171.531 98.1417 173.218 93.4417 174.643C84.0481 177.49 71.0408 179.259 56.6518 179.259C42.2628 179.259 29.2555 177.49 19.8619 174.643C15.1619 173.218 11.3949 171.531 8.81392 169.681C6.22083 167.823 4.91785 165.871 4.91785 163.924C4.91785 161.978 6.22083 160.026 8.81392 158.167C11.3949 156.317 15.1619 154.631 19.8619 153.206C29.2555 150.358 42.2628 148.59 56.6518 148.59C71.0408 148.59 84.0481 150.358 93.4417 153.206C98.1417 154.631 101.909 156.317 104.49 158.167C107.083 160.026 108.386 161.978 108.386 163.924Z" fill="white" fill-opacity="0.4" stroke="#79838D"/>
  <path d="M1.38687 21.8683C1.3155 21.4688 1.26598 21.1388 1.23249 20.8895L111.551 20.8895C111.518 21.1387 111.468 21.4688 111.397 21.8683C111.223 22.8403 110.92 24.2222 110.405 25.8519C109.373 29.113 107.494 33.3533 104.105 37.287C102.467 39.1884 100.304 40.2733 97.9257 41.0397C96.7362 41.423 95.5022 41.7238 94.2599 42.0092C93.9867 42.0719 93.7127 42.134 93.4386 42.1962C92.4731 42.415 91.5073 42.6339 90.5764 42.8817C88.1903 43.517 85.9289 44.3666 84.2707 46.0126C82.5928 47.6782 81.5932 50.095 81.5932 53.7299C81.5932 67.6972 73.5294 78.7621 69.9703 82.8551C68.2383 84.8469 66.2418 86.0182 64.6749 86.6917C63.8921 87.0282 63.2192 87.2392 62.7448 87.3656C62.5077 87.4288 62.3206 87.4708 62.1946 87.4967C62.1316 87.5096 62.0839 87.5185 62.0529 87.524L62.0191 87.5299L62.0119 87.531L62.0114 87.5311L62.0111 87.5312L62.0109 87.5312L62.0108 87.5312L61.5873 87.5962V88.0253L61.5873 154.468L61.0046 148.974L60.9572 148.527H60.5074H52.2712H51.8213L51.774 148.974L51.1963 154.439L51.1963 88.0253V87.5962L50.7727 87.5312L50.7726 87.5312L50.7724 87.5312L50.7721 87.5311L50.7716 87.531L50.7645 87.5299L50.7307 87.524C50.6997 87.5185 50.652 87.5096 50.589 87.4967C50.4629 87.4708 50.2758 87.4288 50.0387 87.3656C49.5643 87.2392 48.8914 87.0282 48.1086 86.6917C46.5417 86.0182 44.5452 84.8469 42.8132 82.8551C39.2541 78.7621 31.1903 67.6972 31.1903 53.7299C31.1903 50.095 30.1907 47.6782 28.5128 46.0127C26.8546 44.3667 24.5932 43.517 22.2071 42.8817C21.2762 42.6339 20.3104 42.415 19.3449 42.1962C19.0709 42.1341 18.7968 42.0719 18.5236 42.0092C17.2813 41.7238 16.0473 41.423 14.8578 41.0397C12.4793 40.2733 10.3163 39.1884 8.67817 37.287C5.28912 33.3533 3.41009 29.113 2.37867 25.8519C1.86325 24.2222 1.56051 22.8403 1.38687 21.8683Z" fill="white" fill-opacity="0.4" stroke="#79838D"/>
  <path d="M61.5266 80.2214H51.257L51.2535 80.2208C51.2256 80.216 51.1826 80.2081 51.1256 80.1966C51.0117 80.1737 50.8424 80.1364 50.6277 80.0802C50.198 79.9679 49.5884 79.7802 48.879 79.4809C47.4588 78.8817 45.6508 77.8403 44.083 76.0706C40.8764 72.4511 34.5344 62.5912 34.5344 50.1296C34.5344 46.8635 33.5524 44.6881 31.9165 43.1931C30.3063 41.7216 28.1192 40.9643 25.8279 40.3984C24.9466 40.1807 24.0342 39.9881 23.1239 39.7958C22.8477 39.7375 22.5717 39.6792 22.2968 39.6203C21.1095 39.3657 19.9345 39.0975 18.808 38.7558C16.5528 38.0717 14.5383 37.1061 13.0614 35.4235C9.96558 31.8965 8.26052 28.85 7.33067 26.6982C6.86559 25.6219 6.59399 24.7684 6.43935 24.1893C6.41 24.0794 6.38485 23.9793 6.3634 23.8895L106.42 23.8895C106.399 23.9793 106.374 24.0793 106.344 24.1893C106.19 24.7684 105.918 25.6219 105.453 26.6981C104.523 28.85 102.818 31.8965 99.7221 35.4235C98.2453 37.1061 96.2307 38.0717 93.9756 38.7558C92.8491 39.0975 91.6741 39.3657 90.4867 39.6203C90.2118 39.6792 89.9359 39.7375 89.6597 39.7958C88.7494 39.988 87.837 40.1807 86.9557 40.3984C84.6644 40.9643 82.4772 41.7216 80.8671 43.1931C79.2312 44.6881 78.2491 46.8635 78.2491 50.1295C78.2491 62.5912 71.9071 72.4511 68.7005 76.0706C67.1327 77.8403 65.3247 78.8817 63.9046 79.4809C63.1952 79.7802 62.5855 79.9678 62.1559 80.0802C61.9412 80.1364 61.7718 80.1737 61.658 80.1966C61.601 80.2081 61.558 80.216 61.5301 80.2208L61.5266 80.2214Z" stroke="#F3F9FF"/>
  </svg>

  </div>;
}

function Lowball(props) {
  let color = getColor(props.cocktail);

  let cherry = "garnish" in props.cocktail ? props.cocktail.garnish.toLowerCase().includes("cherry") : null

  let orange = "garnish" in props.cocktail ? props.cocktail.garnish.toLowerCase().includes("orange") : null

  let twist = "garnish" in props.cocktail ? props.cocktail.garnish.toLowerCase().includes("twist") : null

  let lemon = "garnish" in props.cocktail ? props.cocktail.garnish.toLowerCase().includes("lemon") : null

  if(orange && twist) {
    orange = false;
    twist = true;
  }
  
  if (lemon && twist) {
    lemon = true;
    twist = false;
  }
  let half = true;
  let full = false;
  let rocks = hasIce(props.cocktail);


  return <div className="lowball-display">
<svg viewBox="0 0 151 123" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M35.7485 119.058L24.5805 24.884H130.874L119.706 119.058H35.7485Z" fill="white" fill-opacity="0.4" stroke="#79838D"/>
  {full ? <path d="M29.577 34.5174L125.836 34.5174L115.314 108.709H40.1401L29.577 34.5174Z" fill={color} fill-opacity="0.4"/> : null}
  {half ? <path d="M35.068 65.8145L122.442 65.8145L116.314 108.709H41.14L35.068 65.8145Z" fill={color} fill-opacity="0.4"/> : null}
  {rocks ? <><rect width="24.4945" height="17.3196" transform="matrix(0.741809 -0.482913 1.18304 0.182969 40.3978 82.7605)" fill="#F9FDFF"/>
<rect width="24.4945" height="17.3196" transform="matrix(0.741809 -0.482913 1.18304 0.182969 40.3978 82.7605)" fill="#F9FDFF"/>
<rect width="24.4945" height="17.3196" transform="matrix(0.741809 -0.482913 1.18304 0.182969 40.3978 82.7605)" fill="#F9FDFF"/>
<path d="M60.6834 85.9294L78.8536 74.1007L78.8536 93.1981L60.6834 107.505L60.6834 85.9294Z" fill="#F9FDFF"/>
<path d="M60.6834 85.9294L78.8536 74.1007L78.8536 93.1981L60.6834 107.505L60.6834 85.9294Z" fill="#F9FDFF"/>
<path d="M60.6834 85.9294L78.8536 74.1007L78.8536 93.1981L60.6834 107.505L60.6834 85.9294Z" fill="#E9F4FB"/>
<path d="M60.6833 85.9294L40.4289 82.7631L40.429 103.486L60.6833 107.505L60.6833 85.9294Z" fill="#F9FDFF"/>
<path d="M60.6833 85.9294L40.4289 82.7631L40.429 103.486L60.6833 107.505L60.6833 85.9294Z" fill="#F9FDFF"/>
<path d="M60.6833 85.9294L40.4289 82.7631L40.429 103.486L60.6833 107.505L60.6833 85.9294Z" fill="#E2EEF5"/>
<path d="M109.31 97.3607L99.8597 106.69L79.1507 107.694L88.6014 98.3644L109.31 97.3607Z" fill="#F9FDFF"/>
<path d="M109.31 97.3607L99.8597 106.69L79.1507 107.694L88.6014 98.3644L109.31 97.3607Z" fill="#F9FDFF"/>
<path d="M109.31 97.3607L99.8597 106.69L79.1507 107.694L88.6014 98.3644L109.31 97.3607Z" fill="#DDECF5"/>
<path d="M88.8014 98.3235L79.4692 107.536L75.6401 88.8265L84.4755 77.1865L88.8014 98.3235Z" fill="#F9FDFF"/>
<path d="M88.8014 98.3235L79.4692 107.536L75.6401 88.8265L84.4755 77.1865L88.8014 98.3235Z" fill="#F9FDFF"/>
<path d="M88.8014 98.3235L79.4692 107.536L75.6401 88.8265L84.4755 77.1865L88.8014 98.3235Z" fill="#E2EEF5"/>
<path d="M88.8014 98.3235L109.279 97.3644L105.124 77.0621L84.4754 77.1865L88.8014 98.3235Z" fill="#F9FDFF"/>
<path d="M88.8014 98.3235L109.279 97.3644L105.124 77.0621L84.4754 77.1865L88.8014 98.3235Z" fill="#F9FDFF"/>
<path d="M88.8014 98.3235L109.279 97.3644L105.124 77.0621L84.4754 77.1865L88.8014 98.3235Z" fill="#E9F4FB"/>
<path d="M89.8081 52.8744L102.657 49.5201L121.094 59.0053L108.245 62.3596L89.8081 52.8744Z" fill="#F9FDFF"/>
<path d="M89.8081 52.8744L102.657 49.5201L121.094 59.0053L108.245 62.3596L89.8081 52.8744Z" fill="#F9FDFF"/>
<path d="M89.8081 52.8744L102.657 49.5201L121.094 59.0053L108.245 62.3596L89.8081 52.8744Z" fill="#F9FDFF"/>
<path d="M108.051 62.295L120.739 58.9828L114.7 77.1004L101.229 82.7632L108.051 62.295Z" fill="#F9FDFF"/>
<path d="M108.051 62.295L120.739 58.9828L114.7 77.1004L101.229 82.7632L108.051 62.295Z" fill="#F9FDFF"/>
<path d="M108.051 62.295L120.739 58.9828L114.7 77.1004L101.229 82.7632L108.051 62.295Z" fill="#E9F4FB"/>
<path d="M108.051 62.295L89.8368 52.8867L83.2841 72.5466L101.229 82.7632L108.051 62.295Z" fill="#F9FDFF"/>
<path d="M108.051 62.295L89.8368 52.8867L83.2841 72.5466L101.229 82.7632L108.051 62.295Z" fill="#F9FDFF"/>
<path d="M108.051 62.295L89.8368 52.8867L83.2841 72.5466L101.229 82.7632L108.051 62.295Z" fill="#E2EEF5"/>
<path d="M53.9104 79.6309L50.5561 66.7816L60.0414 48.3452L63.3957 61.1945L53.9104 79.6309Z" fill="#F9FDFF"/>
<path d="M53.9104 79.6309L50.5561 66.7816L60.0414 48.3452L63.3957 61.1945L53.9104 79.6309Z" fill="#F9FDFF"/>
<path d="M53.9104 79.6309L50.5561 66.7816L60.0414 48.3452L63.3957 61.1945L53.9104 79.6309Z" fill="#F9FDFF"/>
<path d="M63.3311 61.3882L60.0189 48.7L78.1364 54.7387L83.7993 68.2104L63.3311 61.3882Z" fill="#F9FDFF"/>
<path d="M63.3311 61.3882L60.0189 48.7L78.1364 54.7387L83.7993 68.2104L63.3311 61.3882Z" fill="#F9FDFF"/>
<path d="M63.3311 61.3882L60.0189 48.7L78.1364 54.7387L83.7993 68.2104L63.3311 61.3882Z" fill="#E9F4FB"/>
<path d="M63.3311 61.3882L53.9227 79.6022L73.5826 86.1549L83.7993 68.2104L63.3311 61.3882Z" fill="#F9FDFF"/>
<path d="M63.3311 61.3882L53.9227 79.6022L73.5826 86.1549L83.7993 68.2104L63.3311 61.3882Z" fill="#F9FDFF"/>
    <path d="M63.3311 61.3882L53.9227 79.6022L73.5826 86.1549L83.7993 68.2104L63.3311 61.3882Z" fill="#E2EEF5"/> </> : null}
  {orange ? <><circle cx="24.0175" cy="24.2743" r="23.6051" fill="#FFB800"/>
    <circle cx="24.0175" cy="24.2742" r="21.6582" fill="#FFE297"/> </> : null }
  {twist ? <path d="M69.48 61.8741C64.7566 61.7981 59.5709 56.1329 57.5684 53.3098C56.8319 52.5584 55.4865 50.0389 55.9973 45.9717C57.4201 45.2814 60.5252 44.72 61.5624 47.9973C62.8589 52.0939 65.0098 53.8434 68.1409 52.5665C71.272 51.2896 78.9861 52.4058 83.374 54.0028C87.7619 55.5999 92.8259 64.3301 93.1204 68.1828C93.4149 72.0356 87.1545 77.2481 85.2679 77.7697C83.3813 78.2913 81.9303 75.9508 81.364 72.8449C80.7977 69.739 75.3842 61.969 69.48 61.8741Z" fill="url(#paint0_linear_41_1099)"/> : null }
  { (cherry || twist) ? <><line x1="42.6668" y1="20.5603" x2="75.0543" y2="58.3361" stroke="#ABABAB"/>
    <ellipse cx="41.9465" cy="19.4452" rx="2.49072" ry="4.1512" transform="rotate(-35 41.9465 19.4452)" fill="#C4C4C4"/> </> : null}
  {cherry ? <circle cx="68.2549" cy="49.5455" r="8.21035" fill="url(#paint1_linear_41_1099)"/> : null}
  { lemon ? <path d="M76.4496 66.6207C73.3553 70.1902 65.6821 70.5212 62.2323 70.2405C61.1833 70.3217 58.3885 69.7328 55.6011 66.7273C55.9869 65.1935 57.5527 62.4541 60.73 63.7661C64.7016 65.4062 67.4243 64.8831 68.4588 61.6638C69.4933 58.4444 75.3069 53.2525 79.3508 50.9178C83.3947 48.583 93.3374 50.3154 96.4781 52.5663C99.6188 54.8172 99.5878 62.9635 98.7746 64.744C97.9615 66.5245 95.2359 66.1316 92.4926 64.5689C89.7493 63.0063 80.3174 62.1589 76.4496 66.6207Z" fill="url(#paint2_linear_41_1099)"/> : null }
  {half ? <path d="M34.068 65.8145L121.442 65.8145L115.314 108.709H40.14L34.068 65.8145Z" fill={color} fill-opacity="0.4"/> : null}
  { full ? <path d="M29.577 34.5174L125.836 34.5174L115.314 108.709H40.1401L29.577 34.5174Z" fill={color} fill-opacity="0.4"/> : null}
<path d="M28.14 25.384H127.314L115.314 109.709H40.14L28.14 25.384Z" fill="url(#paint3_linear_41_1099)"/>
<path d="M40.5739 109.209L28.7162 25.884H126.738L114.88 109.209H40.5739Z" stroke="#D2D2D2" stroke-opacity="0.5"/>
<path d="M35.7485 119.058L24.5805 24.884H130.874L119.706 119.058H35.7485Z" stroke="#79838D"/>
<defs>
<linearGradient id="paint0_linear_41_1099" x1="52.8491" y1="54.6213" x2="93.108" y2="69.2743" gradientUnits="userSpaceOnUse">
<stop stop-color="#FF9D42"/>
<stop offset="0.9999" stop-color="#FFC700" stop-opacity="0.911458"/>
<stop offset="1" stop-color="#FFD542" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_41_1099" x1="68.2549" y1="41.3352" x2="68.2549" y2="57.7559" gradientUnits="userSpaceOnUse">
<stop stop-color="#F43232"/>
<stop offset="1" stop-color="#FF0000"/>
</linearGradient>
<linearGradient id="paint2_linear_41_1099" x1="60.2035" y1="74.6987" x2="97.3063" y2="53.2774" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFEC42"/>
<stop offset="0.9999" stop-color="#FFC700" stop-opacity="0.911458"/>
<stop offset="1" stop-color="#FFD542" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_41_1099" x1="35.3815" y1="69.657" x2="117.025" y2="68.6466" gradientUnits="userSpaceOnUse">
<stop stop-color="white" stop-opacity="0.03"/>
<stop offset="0.200587" stop-color="white" stop-opacity="0.1"/>
<stop offset="0.380986" stop-color="white" stop-opacity="0.47"/>
<stop offset="0.787253" stop-color="white" stop-opacity="0.42"/>
<stop offset="1" stop-color="white" stop-opacity="0.08"/>
</linearGradient>
</defs>
</svg>
  </div>;
}
